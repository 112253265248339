import { Helmet } from 'react-helmet-async';

const makeWebsiteJsonLd = () => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'DW - Learn German',
  alternateName: 'Deutsche Welle - Learn German',
  url: 'https://learngerman.dw-world.cn/',
});

export const JsonLdScript = () => {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(makeWebsiteJsonLd())}</script>
    </Helmet>
  );
};
